import React, {useState, useEffect} from "react";
import  {Upload, Input, Button, Spin, Space, Image} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import useAxios from "../hooks/useAxios";
import {BACKEND_URL} from "../Atoms";
import axios from "axios";
import {WhiteButton} from "../common/DeskComponent";

const zipImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706075032586bU3aRddNInrBEPDgyn.png';
const pdfImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706244143075FiNf626cYM4AQ2H.png';
const xlsImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706244119108aWg89gXKMaKc4b0bza.png';

/**
 * readOnly: b2c history에서 사용
 * downloadName: b2b에서 사용 중. (boAdmin도 사용)
 * downloadOnly : boAdmin에서 사용 중.
 */
const PublicFileUploader = ({onChange, imageUrl, buttonText, readOnly, downloadName, downloadOnly, acceptImage}) => {

    const [loading, setLoading] = useState(false);
    const {axiosGet, axiosPost} = useAxios();

    const [uploadedUrl, setUploadedUrl] = useState(imageUrl);
    console.log('PublicFileUploader imageUrl:', imageUrl);

    //생성후 들어오는 경우가 있어 필요.
    useEffect(() => {
      setUploadedUrl(imageUrl);
    }, [imageUrl]);

    const onFileChange = async (e) => {
        console.log("onFileChange:", e);
        //let files = e.target.files;

        let uploadingFile = e.file?.originFileObj; //(files.length > 0)? files[0] : null;

        console.log("uploadingFile:", uploadingFile);
        if (uploadingFile) {
            //do upload
            setLoading(true);

            let lastIndex = uploadingFile.name.lastIndexOf('.');
            let fileExtension = (lastIndex > 0)? uploadingFile.name.substring(lastIndex) : '.file' ;
            console.log('fileExtension:', fileExtension);

            //returnURL(imageUrl) 을 미리 받아야 함.
            // const {presignedUrl, returnUrl}  = await axiosGet(`/api/file/publicFile/generatePresignedUrl/${fileExtension}`);
            const {data}  = await axios.get(`${BACKEND_URL}/api/file/publicFile/generatePresignedUrl/${fileExtension}`);
            let presignedUrl = data?.presignedUrl;
            let returnUrl =  data?.returnUrl;

            console.log(presignedUrl);

            try {
              const ret = await axios.put(presignedUrl, uploadingFile, {
                headers: {
                  'Content-Type': uploadingFile.type,
                },
              })
              console.log('retData:', ret);
              if(ret.status == 200) {
                console.log('retUrl:', returnUrl);
                setUploadedUrl(returnUrl);
                if (onChange) {
                  onChange(returnUrl);
                }
              }
            }catch(e) {
              console.log(e);
            }
            setLoading(false);
        }
    }

    const downloadImage = async (url, filename) => {
        console.log('download click: ', filename);
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;
            a.download = filename || 'download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(urlObject); // 생성된 URL 객체 해제
        } catch (error) {
            console.error('Download Error:', error);
        }

        // 새로운 탭이 열림.
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = filename || 'download';
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
    }

    return (
        <>
            {/*<Input type={'file'} disabled={loading} style={{fontSize: 12, width: 200}} onChange={onFileChange}/>*/}
            {!loading &&
                <>
                  {(uploadedUrl || readOnly)?
                    <Space>
                      { //이미지미리보기 + 특정확장자 처리.
                          (uploadedUrl && uploadedUrl.indexOf('.zip')>0) ? <Image style={{height: 35}} src={zipImageUrl}/>
                          :
                          (uploadedUrl && uploadedUrl.indexOf('.pdf')>0) ? <Image style={{height: 35}} src={pdfImageUrl}/>
                            :
                          (uploadedUrl && uploadedUrl.indexOf('.xls')>0) ? <Image style={{height: 35}} src={xlsImageUrl}/>
                              :
                          <Image style={{height: 40}} src={uploadedUrl}/>
                      }

                      { //다운로드 기능.
                          (downloadName) && <Button onClick={() => downloadImage(uploadedUrl, downloadName)}>다운로드</Button>
                      }

                      {!downloadOnly && //수정 기능 및 증거없음(readOnly) 버튼.
                          <Upload maxCount={1} style={{fontSize: 12, width: 200}} onChange={onFileChange}>
                            {readOnly? //b2c 히스토리에서 readOnly 사용.
                             <WhiteButton disabled={true} style={{paddingLeft:4, paddingRight:4, marginLeft:-5}}> 증거 {uploadedUrl?'':'없음'}</WhiteButton>
                              :
                             <Button type={downloadName?'default':'default'} style={{paddingLeft:4, paddingRight:4, marginLeft:-5}}> 수정 </Button>
                            }
                          </Upload>
                     }
                    </Space>

                    : //최초 파일업로드. : default=흰색( downloadName: b2b에서 사용)
                    (!downloadOnly)?
                    <Upload accept={acceptImage?'image/*':'*'} maxCount={1} style={{fontSize: 12, width: 200}} onChange={onFileChange}>
                         <Button type={'default'} icon={<UploadOutlined/>}>{(buttonText===''|| buttonText)?buttonText:'파일 선택'}</Button>
                    </Upload> : <></>
                  }
                </>
            }
            {loading &&
                <Space>
                    <Spin/>
                </Space>
            }

        </>
    )
}

export default PublicFileUploader;
