import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";
//theme
//import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/themes/saga-orange/theme.css";     
//core
import "primereact/resources/primereact.min.css";                                       
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
import 'react-responsive-modal/styles.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom";

//b2b : b.creditncity.com
import B2bHome  from './pages/b2b/B2bHome';
import B2bLoginLanding  from './pages/b2b/B2bLoginLanding';
import B2bLogin  from './pages/b2b/B2bLogin';
import B2bSignup  from './pages/b2b/B2bSignup';
import B2bSignupWait  from './pages/b2b/B2bSignupWait';
import B2bPredata  from './pages/b2b/B2bPredata';
import B2bPredataWait  from './pages/b2b/B2bPredataWait';
import B2bPlanConfirm from "./pages/b2b/B2bPlanConfirm";
import B2bContractWait  from './pages/b2b/B2bContractWait';
import CreditList from "./pages/b2b/creditManage/CreditList";
import CreditUnitAdd from "./pages/b2b/creditAdd/CreditUnitAdd";

import StoryTelling from "./pages/storyTelling/StoryTelling";
import StoryReRequest from "./pages/storyTelling/StoryReRequest";

import Endorsement from "./pages/endorsement/Endorsement"
import EndorseLoginLanding  from './pages/endorsement/EndorseLoginLanding';
import EndorseLogin  from './pages/endorsement/EndorseLogin';
import EndorseSignup  from './pages/endorsement/EndorseSignup';

import { MainPage } from './pages/MainPage';
import { ChatPage } from './pages/ChatPage';
import { Layout } from './pages/Layout';
import { LoginPage } from './pages/LoginPage';
import { MyPage } from './pages/MyPage';
import { NoticePage } from './pages/NoticePage';
import { CaseListPage } from './pages/CaseListPage';
import { CompanyPage } from './pages/CompanyPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { DisclaimerPage } from './pages/DisclaimerPage';
import { FaqPage } from './pages/FaqPage';
import { NewsPage } from './pages/NewsPage';
import { EditProfilePage } from './pages/EditProfilePage';
import { EditUserPage } from './pages/EditUserPage';
import { AppleCallback, CallbackLayout, GoogleCallback, KakaoCallback, NaverCallback } from './pages/SocialCallback';
import { SocialAgreePage } from './pages/SocialAgreePage';
import { CreditorPage } from './pages/CreditorPage';
import { SignInPage } from './pages/SignInPage';
import { DebtorPage } from './pages/DebtorPage';
import { UserQuitPage } from './pages/UserQuitPage';
import { QuitReasonPage } from './pages/QuitReasonPage';
import { QuitEndPage } from './pages/QuitEndPage';
import { PaymentPage } from './pages/PaymentPage';
import { PaymentCallback } from './pages/PaymentCallback';
import CloseInicis from './pages/PayClose';
import DebtorChatLanding from "./pages/DebtorChatLanding";
import GasUnpaidView from "./pages/GasUnpaidView";
import FeePayment from "./pages/FeePayment";
import CncityFinalNoti from "./pages/etc/CncityFinalNoti";
import PreBondPage from "./pages/PreBondPage";
import PreBondPrePage from "./pages/PreBondPrePage";
import EndorseSignupWait from "./pages/endorsement/EndorseSignupWait";

const router = createBrowserRouter(
  createRoutesFromElements(
    // 기존의 Route를 createRoutesFormElements에 전달하기
    // callback류와 App류를 구분하여, Callback시 불필요한 splash 나오지 않도록 함. 
    <Route > {/*element={<App/>}>  */}
      <Route element={<App/>}>  {/* Main layout */}
        <Route path="/" element={<MainPage />}/>
        <Route path="/b2b" element={<B2bHome />}/>
        <Route path="/b2bLoginLanding" element={<B2bLoginLanding />}/>
        <Route path="/b2bLogin" element={<B2bLogin />}/>
        <Route path="/b2bSignup" element={<B2bSignup />}/>
        <Route path="/b2bSignupWait" element={<B2bSignupWait />}/>
        <Route path="/b2bPredata" element={<B2bPredata />}/>
        <Route path="/b2bPredataWait" element={<B2bPredataWait />}/>
        <Route path="/b2bPlanConfirm" element={<B2bPlanConfirm />}/>
        <Route path="/b2bContractWait" element={<B2bContractWait />}/>

        <Route path="/b2b/creditList" element={<CreditList />}/>
        <Route path="/b2b/creditUnitAdd" element={<CreditUnitAdd />}/>

        <Route path="/desk/storyTelling/:counselId" element={<StoryTelling />}/>
        <Route path="/desk/storyReRequest/:counselId" element={<StoryReRequest />}/>

        {/*삼천리 위임계약서 */}
        <Route path="/endorsement" element={<Endorsement />}/>
        <Route path="/endorsement/loginLanding" element={<EndorseLoginLanding />}/>
        <Route path="/endorsement/login" element={<EndorseLogin />}/>
        <Route path="/endorsement/signup" element={<EndorseSignup />}/>
        <Route path="/endorsement/signupWait" element={<EndorseSignupWait />}/>



        {/*채무자용 채팅방 랜딩+채팅방 */}
        <Route path="/dc/:chatId" element={<DebtorChatLanding/>} />
        <Route path="/dca/:chatId" element={<ChatPage debtor={true}/>} />
        {/*가스요금 미납내역:  */}
        <Route path="/gv" element={<GasUnpaidView/>} />
        <Route path="/gv/:chatId" element={<GasUnpaidView/>} />

        {/* 씨엔씨티 - 내용증명 */}
        <Route path="/cncity/finalnoti" element={<CncityFinalNoti />} />

        {/*아래2개는 test용도 미사용예정*/}
        {/*<Route path="/debtorChat" element={<ChatPage debtor={true}/>} />*/}
        {/*<Route path="/debtorChat/:chatId" element={<ChatPage debtor={true}/>} />*/}

        <Route path="/feePayment" element={<FeePayment />} />


        <Route path="/chat" element={<ChatPage />} />
        <Route path="/chat/:chatId" element={<ChatPage />} />

        <Route path="/signin" element={<SignInPage/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/mypage" element={<MyPage/>} />
        <Route path="/notices" element={<NoticePage/>} />
        <Route path="/chatlist" element={<CaseListPage/>} />
        <Route path="/company" element={<CompanyPage/>} />
        <Route path="/terms" element={<TermsPage/>} />
        <Route path="/privacy" element={<PrivacyPage/>} />
        <Route path="/disclaimer" element={<DisclaimerPage/>} />
        <Route path="/news" element={<NewsPage/>} />
        <Route path="/faq" element={<FaqPage/>} />
        <Route path="/profile" element={<EditProfilePage/>} />
        <Route path="/edituser" element={<EditUserPage/>} />
        <Route path="/agree" element={<SocialAgreePage/>} />
        <Route path="/creditor/:chatId" element={<CreditorPage/>} />
        <Route path="/debtor/:chatId" element={<DebtorPage/>} />
        <Route path="/quit" element={<UserQuitPage/>} />
        <Route path="/qreason" element={<QuitReasonPage/>} />
        <Route path="/qend" element={<QuitEndPage/>} />
        <Route path="/payment/:chatId" element={<PaymentPage/>} />
        <Route path="/pbs/:preBondId?" element={<PreBondPage />} />
        <Route path="/pbs/pre/:preBondId?" element={<PreBondPrePage />} />

        <Route path="/payclose" element={<CloseInicis/>}/>
      </Route>
      <Route element={<CallbackLayout/>}>  {/* Callback Layout */}
        <Route path="/callback_kakao" element={<KakaoCallback/>}/>
        <Route path="/callback_naver" element={<NaverCallback/>}/>
        <Route path="/callback_google" element={<GoogleCallback/>}/>
        <Route path="/callback_apple" element={<AppleCallback/>}/>
        <Route path="/paid/:oid" element={<PaymentCallback/>}/>
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

// 자꾸 2번 불려서<React.StrictMode> 제거함. 
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus/>  {/* RecoilNext to use recoil outside react component */}
      <RouterProvider router={router} fallbackElement={<div>Loading...</div>}/>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
