import './App.css';
import { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import {BoNetUtil, isCreditnCitycEnv, useEffectOnce} from './common/Util';
import { useRecoilValue, useRecoilState } from 'recoil';
import { BoTokenAtom, BACKEND_URL, BoUserIdAtom, UserIdAtom } from './Atoms';
import _ from 'lodash';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import AuthContextProvider from './common/AuthContext';
import GoogleTagManager from "./GoogleTagManager";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

addLocale('kr', {
    firstDayOfWeek: 1,
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '초기화',
    //...
});

function App() {

    //const boToken = useRecoilValue(BoTokenAtom);
    //const boUserId = useRecoilValue(BoUserIdAtom);
    
    const [loading, setLoading] = useState(false);
    const [tick, setTick] = useState(0);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const delay = 200;
    
    // catch PWA install event
    useEffect(() => {
        /*
        const isDeviceIOS =
            /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
        setIsIOS(isDeviceIOS);
        //setIsIOS(false);
        */
        const handleBeforeInstallPrompt = e => {
            console.log(`handleBeforeInstallPrompt ${e}`);
            e.preventDefault();
            setDeferredPrompt(e);
            //setIsShown(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt,
            );
        };
    }, []);

    //2311 gary: AuthContext로 이동.
    // useEffectOnce(() => {
    //     _.delay(() => setLoading(false), delay); //이동하면서 delay만 남겨둠.
    // }, []);

    if (loading) {
        return (
            (window.location.host.startsWith('b.') || window.location.href.includes('/desk/') )?
                <></>  //스플래시 스크린 제외.
                :
                <div className="App flex justify-content-center"
                     style={{backgroundColor: "#ffd41c", height: "100vh"}}>
                    <div className="flex align-items-center text-3xl font-bold">
                        {/*<img src="/assets/bi-black.svg" className="mr-3" style={{height: "3rem"}}/>*/}
                        {/*<img src="/assets/bondcity-black.svg" style={{height: "3rem"}}/>*/}
                        {window.location.host.startsWith('2ai.')?
                            <img src="/assets/logo2ai.png" style={{height: "5rem"}}/>
                            :
                            <img src="/assets/logoC.png" style={{height: "5rem"}}/>
                        }
                    </div>
                </div>

        )
    } else {
        return (
            <GoogleReCaptchaProvider reCaptchaKey="6LfVu-UpAAAAAGOWDvwy0U-d0xoZTOFxO8uXh0Ch"
                                     language="ko"
                                     useRecaptchaNet={true}
                                     useEnterprise={false}
            >
                <AuthContextProvider>
                    <div className={ (window.location.host.startsWith('b.') || window.location.href.includes('/desk/') || window.location.href.includes('/endorsement'))? "Desktop": "App"}>
                            <Outlet context={deferredPrompt}/> {/* outlet으로 prop전달하는 법 */}
                    </div>

                    {isCreditnCitycEnv() &&
                        <GoogleTagManager gtmId={'GTM-NHKQ5KR6'}/>
                    }

                </AuthContextProvider>
            </GoogleReCaptchaProvider>
        );
    }
}

export default App;
