import React, {useEffect, useRef, useState} from "react";
import {Div} from "../common/DeskComponent";
import classNames from "classnames";
import {InputText} from "primereact/inputtext";
import {OrangeButton} from "../common/Common";
import {isDevEnv} from "../common/Util";
import useAxios from "../hooks/useAxios";
import {Button, Flex, message, Select, Space} from "antd";
import {useNavigate} from "react-router";
import PublicFileUploader from "../components/PublicFileUploader";




const CreditorDebtorInfo = ({preBondId}) => {

    console.log('CreditorDebtorInfo preBondId=', preBondId);

    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();
    const {axiosGet, axiosPost} = useAxios();
    const [preBond, setPreBond] = useState();

    const [creditor, setCreditor] = useState();
    const [debtor, setDebtor] = useState();



    useEffect(()=> {
        //preBond세팅 필요. preBond.drivenCmId 를 이용해야 함.
        firstSearch();
    },[])

    const firstSearch = async()=> {
        let ret = await axiosGet(`/api/v1/prebonds/preBond/${preBondId}`)
        if (ret) {
            console.log('preBond:', ret);
            setPreBond(ret);
        }else {
            alert('오류 - 가채권 가져오기 실패:' + preBondId);
        }
    }

    //creditor,debtor가져오기. by drivenCmId.
    useEffect(()=> {

        //creditorName, debtorName이 preBond에만 있으면 creditor/debtor에 추가 설정.
        getCreditorAndDebtorFromCm(preBond);

    },[preBond])

    const getCreditorAndDebtorFromCm = async(preBond) => {
        if (!preBond)  return;
            
        let ret = await axiosGet(`/api/v1/prebonds/preBond/creditorAndDebtor/${preBond.drivenCmId}`)
        if (ret) {
            console.log('getCreditorAndDebtorFromCm:', ret);

            if (!ret.creditor.이름) {
                ret.creditor.이름 = preBond.creditorName;
            }
            if (!ret.creditor.핸드폰번호) {
                ret.creditor.핸드폰번호 = preBond.signupUserPhone;
            }

            if (!ret.debtor.이름) {
                ret.debtor.이름 = preBond.debtorName;
            }

            setCreditor(ret.creditor);
            setDebtor(ret.debtor);
        }else {
            alert('오류 - 원장정보 가져오기 실패:' + preBond.drivenCmId);
        }
    }


    const DaumPostcode = () => {
        const handleOpenPostcode = () => {
            new window.daum.Postcode({
                oncomplete: function (data) {
                    // 주소 선택 후 실행될 로직을 작성
                    console.log(data); // 선택된 주소 데이터
                    console.log(data.roadAddress, data.zonecode);

                    setDebtor(prev => ({...prev, 우편번호:data.zonecode, 주소1:data.roadAddress}));
                },
            }).open();
        };

        useEffect(() => {
            // Daum Postcode API를 로드하는 스크립트 실행
            const script = document.createElement('script');
            script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
            document.body.appendChild(script);

            return () => {
                // 컴포넌트가 언마운트될 때 스크립트 정리
                document.body.removeChild(script);
            };
        }, []);

        return (
            <div>
                {/*<button onClick={handleOpenPostcode}>우편번호 검색</button>*/}
                <Button style={{marginTop:24}} size={'small'} type={'primary'} onClick={handleOpenPostcode}>우편번호 검색</Button>
            </div>
        );
    };

    const handleCreditorChange = (e) => {
        console.log(e);
        setCreditor(prev => ({...prev, [e.target.name]:e.target.value}));
    }

    const handleDebtorChange = (e) => {
        console.log(e);
        setDebtor(prev => ({...prev, [e.target.name]:e.target.value}));
    }

    const onDebtorGubunChange = (value) => {
        setDebtor(prev => ({...prev, ['채무자구분']:value}));
    }

    const onSave = async() => {
        console.log('onSave', creditor,debtor);

        let ret = await axiosPost(`/api/v1/prebonds/preBond/creditorAndDebtor/${preBond.drivenCmId}`, {creditor, debtor})
        if (ret) {
            //messageApi.info('저장완료');
            alert('저장되었습니다. '); //문구부족. 채권자 이메일은 없어 문구입력못함.

            navigate('/');
        }else {
            alert('저장 실패')
        }
    }

    return(
        <>
            {contextHolder}

            <div className="text-left p-3">
                <Div style={{fontSize: 20, fontWeight: 900}}> 1. 채권자 정보 입력</Div>

                <div className="mb-2" style={{marginTop: 16}}>채권자 이름</div>
                <InputText name="이름" value={creditor?.이름} onChange={handleCreditorChange}
                           placeholder="텍스트 입력"
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 16}}>채권자 이메일</div>
                <InputText name="이메일" value={creditor?.이메일} onChange={handleCreditorChange}
                           placeholder="이메일 입력"
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 16}}>채권자 휴대폰</div>
                <InputText name="핸드폰번호" value={creditor?.핸드폰번호} onChange={handleCreditorChange}
                           placeholder="휴대폰 입력"
                           className={classNames("w-full")}/>

                {/*    <div className="mb-2" style={{marginTop: 16}}>전체주소</div>*/}
                {/*    <InputText name="전체주소" value={creditor?.전체주소} onChange={handleCreditorChange}*/}
                {/*               placeholder="텍스트 입력"*/}
                {/*               className={classNames("w-full")}/>*/}
                {/*    */}
            </div>

            <div style={{marginTop: -16}} className="text-left p-3">
                <Div style={{fontSize: 20, fontWeight: 900}}> 2. 채무자 정보 입력 (아는 내용만) </Div>

                <div className="mb-2" style={{marginTop: 16}}>채무자 이름(혹은 회사명)</div>
                <InputText name="이름" value={debtor?.이름} onChange={handleDebtorChange}
                           placeholder="텍스트 입력"
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 16}}>채무자 휴대폰</div>
                <InputText name="휴대폰번호" value={debtor?.휴대폰번호} onChange={handleDebtorChange}
                           placeholder="(휴대폰번호 입력)"
                           className={classNames("w-full")}/>

                <Flex><Space>
                    <div className="mb-2" style={{marginTop: 32, fontWeight: 900}}>주소지</div>
                    {/*<Button type={'primary'} style={{marginTop:8}}  size={'small'}> 우편번호 검색 </Button>*/}
                    <DaumPostcode/>
                    {debtor?.우편번호 &&
                        <div style={{fontSize: 14, marginTop: 24}}> 우편번호: {debtor?.우편번호}  </div>
                    }
                </Space></Flex>

                {/*<div className="mb-2" style={{marginTop: 16}}>주소(우편번호 검색주소)</div>*/}
                <InputText name="주소1" value={debtor?.주소1} //onChange={handleDebtorChange}
                           disabled={true} style={{fontWeight: 900}}
                           placeholder="(우편번호 검색)"
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 8, fontSize: 14}}> 상세주소</div>
                <InputText name="주소2" value={debtor?.주소2} onChange={handleDebtorChange}
                           placeholder="(상세주소 입력)"
                           className={classNames("w-full")}/>


                <div className="mb-2" style={{marginTop: 32}}>E-mail</div>
                <InputText name="이메일" value={debtor?.이메일} onChange={handleDebtorChange}
                           placeholder="(이메일 입력)"
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 16}}>마지막으로 언제 위 전화번호로 연락 되었는지?</div>
                <InputText name="마지막폰연락일" value={debtor?.마지막폰연락일} onChange={handleDebtorChange}
                           placeholder=""
                           className={classNames("w-full")}/>

                <div className="mb-2" style={{marginTop: 32}}>상대방 카톡프로필 확인되시면 프로필 및 상대가 등록해 놓은 이름을 캡쳐해서 보내주세요</div>
                <PublicFileUploader imageUrl={debtor?.카톡프로필이미지} acceptImage={true}
                                    downloadName = {'카톡프로필이미지'} //downloadOnly={replyExist}
                                    onChange={(returnUrl) => setDebtor(prev => ({...prev, 카톡프로필이미지:returnUrl}))}/>



                {/*<div className="mb-2" style={{marginTop: 16}}>채무자 구분</div>*/}
                {/*<Select className={classNames("w-full")}*/}
                {/*        value = {debtor?.채무자구분}*/}
                {/*    onChange={onDebtorGubunChange}*/}
                {/*    options={[{value:'개인'},{value:'개인사업자'},{value:'법인'}]}*/}
                {/*/>*/}

                {/*{debtor?.채무자구분 === '개인' &&*/}
                {/*    <>*/}
                {/*        <div className="mb-2" style={{marginTop: 16}}>주민번호</div>*/}
                {/*        <InputText name="주민번호" value={debtor?.주민번호} onChange={handleDebtorChange}*/}
                {/*                   placeholder="텍스트 입력"*/}
                {/*                   className={classNames("w-full")}/>*/}


                {/*    </>*/}
                {/*}*/}

                {/*{(debtor?.채무자구분 === '법인' || debtor?.채무자구분 === '개인사업자' ) &&*/}
                {/*    <>*/}
                {/*        <div className="mb-2" style={{marginTop: 16}}>사업자번호</div>*/}
                {/*        <InputText name="사업자번호" value={debtor?.사업자번호} onChange={handleDebtorChange}*/}
                {/*                   placeholder="텍스트 입력"*/}
                {/*                   className={classNames("w-full")}/>*/}


                {/*    </>*/}
                {/*}*/}

                {/*{debtor?.채무자구분 === '법인' &&*/}
                {/*    <>*/}
                {/*        <div className="mb-2" style={{marginTop: 16}}>대표자명</div>*/}
                {/*        <InputText name="대표자명" value={debtor?.대표자명} onChange={handleDebtorChange}*/}
                {/*                   placeholder="텍스트 입력"*/}
                {/*                   className={classNames("w-full")}/>*/}


                {/*    </>*/}
                {/*}*/}


            </div>

            <br/>
            <OrangeButton label={`입력 완료`} onClick={onSave}/>

        </>
    )
}

export default CreditorDebtorInfo;