import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Descriptions, Input, Result, Skeleton, Space, Spin, Steps, Typography} from "antd";
import {CheckOutlined, LikeOutlined} from "@ant-design/icons"
import {useRecoilValue} from "recoil";
import {Footer} from "./MainPage";
import {addComma, BoNetUtil, isEmptyString} from "../common/Util";
import {BACKEND_URL, BoUserTypeAtom} from "../Atoms";
import axios from "axios";
import FeePayment from "./FeePayment";
import CreditorDebtorInfo from "./CreditorDebtorInfo";
import {isMobile} from 'react-device-detect';
import {getFrontUrl, getMobileOS, isDevEnv} from "../common/Util"

const PreBondPrePage = () => {
    const navigate = useNavigate();
    const {preBondId} = useParams();

    useEffect(() => {
        if (isMobile) {
            let os = getMobileOS();
            if (os === 'iOS') {
                window.location.href = getFrontUrl() + `/pbs/${preBondId}`; //ios폰은 강제이동.
            }
        }
    }, [preBondId]);


    const openInDefaultBrowser = (url) => {
        let osUrl = url;

        if (isMobile) {
            let os = getMobileOS();

            // if (os === 'iOS') { //최신폰에서 안됨.
            //     //잘안됨.osUrl = `safari://${url.replace(/^https?:\/\//, "")}`
            //     window.location.href = url;
            //     return;
            //
            // }else
            if (os === 'android') {
                osUrl = `intent://${url.replace(
                    /^https?:\/\//,
                    ""
                )}#Intent;scheme=https;package=com.android.chrome;end`;
            }
        }

        if (isDevEnv()) {
            alert(osUrl);
        }

        const newWindow = window.open(osUrl, "_blank", "noopener,noreferrer");
        if (newWindow) {
            newWindow.opener = null; // 보안 강화
        }
    };

    return (
        <div>
            <header>
                <div className="flex px-3 py-3 border-bottom-1 align-items-center"
                     style={{borderColor: "#F5F4F3", color: "#191919"}}>
                    <div className="p-0 m-0 flex align-items-center">
                        <a href="/public" onClick={() => navigate("/")}>
                            <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "3.1rem"}}/>
                        </a>
                    </div>
                    <div className="ml-auto flex align-items-center">
                        <Typography.Title level={5} style={{marginTop:10}}>상담확인 결제</Typography.Title>
                    </div>
                </div>
            </header>
            {isEmptyString(preBondId) && (
                <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                    <Result status="error"
                            title="유효하지 않은 URL"
                            subTitle="상담원에게 결제 URL을 다시 문의하시기 바랍니다"
                    />
                </div>
            )}
            {!isEmptyString(preBondId) && (
                <>

                    <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                        <Result status="info"
                                title={<div>채권도시에 접속해서<br/> 결제를 시작합니다.<br/><span style={{fontSize:18}}>(휴대폰으로 결제해 주세요)</span></div>}
                                extra={<div className="col-3 orange-back text-center p-3 cursor-pointer"
                                            style={{width: 200, margin: "0 auto",fontSize:18}}
                                            onClick={() => openInDefaultBrowser(getFrontUrl() + `/pbs/${preBondId}`)}>
                                    채권도시 접속
                                </div>}
                            />
                    </div>

                </>
            )}
            <Footer/>

        </div>
);
};

export default PreBondPrePage;