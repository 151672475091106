import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {message, Pagination} from "antd";
import {Document, Page, pdfjs} from 'react-pdf';

//Front에서는 BO와 다르게, public폴더 이용  js는 없어서 mjs로 추가.
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
// try {
//     pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//         'pdfjs-dist/build/pdf.worker.min.js',
//         import.meta.url,
//     ).toString();
// } catch (e) {console.log(e)}


//2404- useClipboard:true이면 canvas에도 그려놓고, clipboard로 복사함수를 제공.
// eslint-disable-next-line react/display-name
const PdfViewer = forwardRef(({file, onLoadSuccess, onLoadError, showPagination, useClipboard}, _ref) => {
    const [pageCount, setPageCount] = useState(0);
    const [currentPageNo, setCurrentPageNo] = useState(0);

    const canvasRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        //2404: 클립보드 복사기능을 위해 canvas에 그리는 작업
        if (useClipboard) {
            fetchPdf2Canvas(1); //1페이지를 기본 출력.
        }
    }, [file]);

    const onDocumentLoadSuccess = ({numPages}) => {
        setPageCount(numPages);
        setCurrentPageNo(1);
        if (onLoadSuccess) {
            onLoadSuccess(numPages);
        }
    };

    useImperativeHandle(_ref, () => ({
        setPageNo: (pageNo) => {setPageNoAndCheckCanvas(pageNo)},
        getPageNo: () => currentPageNo,
        copyPage2Clipboard: () => {copyCanvasToClipboard()},    // 현재 페이지 -> 이미지 -> 클립보드로 복사
        currentPageToPngDataUrl: currentPageToPngDataUrl               // 현재 페이지 -> DataUrl (png 이미지)
    }));


    const setPageNoAndCheckCanvas = async (pageNo) => {
        setCurrentPageNo(pageNo)

        //클립보드 사용시에는 canvas에 다시 그림.
        if (useClipboard) {
            console.log(`PdfViewer: draw ${pageNo} Page To canvas`);
            fetchPdf2Canvas(pageNo)
        }
    }


    //2404: 클립보드 복사기능을 위해 canvas에 그리는 작업
    const fetchPdf2Canvas = async (pageNo) => {
        const loadingTask = pdfjs.getDocument(file);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(pageNo); //currentPage만 canvas에 그리기.

        const viewport = page.getViewport({ scale: 1.2}); //size조절 가능.
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
            canvasContext: context,
            viewport: viewport
        };
        await page.render(renderContext).promise;
    };

    //2404: 클립보드 복사기능
    const copyCanvasToClipboard = async () => {
        if (!useClipboard) {
            alert('useClipboard가 true 여야 합니다. ')
            return;
        }

        console.log(`PdfViewer: copyCanvasToClipboard `, currentPageNo);

        const canvas = canvasRef.current;
        canvas.toBlob((blob) => {
            const item = new ClipboardItem({ "image/png": blob });
            navigator.clipboard.write([item]).then(() => {
                console.log("Image copied to clipboard successfully!");

                messageApi.info( '현재 페이지 클립보드로 복사완료');
            }, (error) => {
                console.error("Failed to copy the image to clipboard.", error);
            });
        }, 'image/png');
    };

    /**
     * @param callback (url, currentPageNo, error)
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
     */
    const currentPageToPngDataUrl = (callback) => {
        if (!useClipboard) {
            callback(null, currentPageNo, new Error("useClipboard가 true 여야 합니다"));
            return;
        }

        try {
            console.log("PdfViewer: currentPageToPngDataUrl, currentPageNo =", currentPageNo);
            const canvas = canvasRef.current;
            const dataUrl = canvas.toDataURL("image/png", 1.0); // 1.0 -> full quality
            console.log("PdfViewer: currentPageToPngDataUrl -> png dataUrl =", dataUrl);
            callback(dataUrl, currentPageNo, null);
        } catch (e) {
            console.error("PdfViewer: currentPageToPngDataUrl ->", e);
            callback(null, currentPageNo, e);
        }
    };

    return (
        <>
            {contextHolder}

            {/* 클립보드 사용시에는 숨기고 canvas에 그림*/}
            <div style={{display:useClipboard?'none':'block'}}>
                <Document
                    file={file}     // PDF URL
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onLoadError}
                >
                    {currentPageNo > 0 && (
                        <Page pageNumber={currentPageNo}/>
                    )}
                </Document>
            </div>

            {showPagination && pageCount > 0 &&
                <Pagination style={{textAlign: "center"}}
                            defaultPageSize={1}
                            defaultCurrent={currentPageNo} total={pageCount}
                            onChange={(page) => {
                                setCurrentPageNo(page)
                            }}
                />
            }
            {useClipboard && //클립보드 사용시에는 canvas에 출력. (현재 1.2크기로 확대출력중)
                <canvas ref={canvasRef}></canvas>
            }
        </>
    );
});

export default PdfViewer;

// eslint-disable-next-line react/display-name
export const SimplePagination = forwardRef(({onChange}, _ref) => {
    const [pageCount, setPageCount] = useState(0);

    useImperativeHandle(_ref, () => ({
        setPageCount: (count) => {setPageCount(count)},
    }));

    return (
        <>
            {pageCount > 0 && (
                <Pagination simple={true} hideOnSinglePage={true} pageSize={1}
                            defaultCurrent={1}
                            total={pageCount}
                            onChange={onChange}
                />
            )}
        </>
    );
});
