import { useEffect, useState } from "react";
import { WhiteTopBar, OrangeButton, WhiteButton } from "../common/Common";
import {BACKEND_URL, CounselEnrollAtom, CounselProgressAtom, UserObjectIdAtom} from "../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import { TabView, TabPanel } from 'primereact/tabview';
import classnames from 'classnames';
import { BoNetUtil } from "../common/Util";
import commaNumber from "comma-number";
import dateFormat from "dateformat";
import { Button } from 'primereact/button';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from "react-router";
import useAxios from "../hooks/useAxios";



export function CaseListPage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title="채권목록"/>
            <br/>
            <CaseContent/>
        </div>
    )
}

function CaseContent(props) {

    const userObjectId = useRecoilValue(UserObjectIdAtom);
    const [userChats, setUserChats] = useState([]);
    const [counsels, setCounsels] = useState([]);
    const [caseStatus, setCaseStatus] = useState([]);
    const [bonds, setBonds] = useState([]);  // userChat, counsel, caseStatus 를 합친 것임. 
    const [panels, setPanels] = useState([]);
    const [tick, setTick] = useState(0);  // refresh 하기 위한 key

    useEffect(() => {
        // GET https://vatdahm.com/api/v1/userChats?limit=10000&page=1&type=counsel&userObjectId=6549e5ac942e5b1a8937aff9
        // isActive=true 인것만 가져오게 함. 
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats`, {limit: 10000, page: 1, type: "counsel", userObjectId: userObjectId, isActive: true},
            (resp) => {
                setUserChats(resp.data.data);
                // Promise 사용으로 순서 문제를 해결함 
                const promises = [];
                for (const userChat of resp.data.data) {
                    const p = new Promise((resolve, reject) => {
                        BoNetUtil.get(`${BACKEND_URL}/api/v1/counsels/${userChat.counselObjectId}`, null,
                            (resp) => {
                                resolve(resp.data.data[0]);
                            },
                            (err) => {
                                console.error(err);
                                reject(null);
                            }
                        );
                    })
                    .catch((err) => {});
                    promises.push(p);
                }

                Promise.allSettled(promises).then((results) => {
                    //console.dir(results);  // results는 status 와 value 의 Array임. 
                    setCounsels(results.filter((item) => item.status === "fulfilled").map((item) => item.value));
                });

                const promises2 = [];
                for (const userChat of resp.data.data) {
                    const p = new Promise((resolve, reject) => {
                        BoNetUtil.get(`${BACKEND_URL}/vws/bo/case/${userChat._id}/status`, null,
                            (resp) => {
                                //console.log("case=%o", resp.data);
                                resolve(resp.data);
                            },
                            (err) => {
                                console.error(err);
                                reject(null);
                            }
                        );
                    })
                    .catch((err) => {});
                    promises2.push(p);
                }

                Promise.allSettled(promises2).then((results) => {
                    setCaseStatus(results.filter((item) => item.status === "fulfilled").map((item) => item.value));
                });
            }, 
            (err) => console.error(err)
        );
    }, [tick]);

    // 효율을 위해 counsel 아래에 userChat과 case 를 합친다. 
    useEffect(() => {
        const merged = [];
        //console.log("counsel=%o", counsels);
        //console.log("userChats=%o", userChats);
        //console.log("cases=%o", caseStatus);
        for (const co of counsels) {
            const uidx = userChats.findIndex((elem) => elem.counselObjectId === co._id);
            if (uidx >= 0) {
                //console.log("found userChat %o", uidx);                
                co.userChat = userChats[uidx];
            }
            const cidx = caseStatus.findIndex((elem) => elem.counselId === co._id);
            if (cidx >= 0) {
                //console.log("found case %o", cidx);
                co.case = caseStatus[cidx];
            }
            merged.push(co);            
        }
        console.log("merged=%o", merged);
        setBonds(merged);
    }, [userChats, counsels, caseStatus]);

    useEffect(() => {
        const p = [];
        p.push(<TabPanel header="전체보기" key="all" headerTemplate={faqTabHeaderTemplate}><CaseBody bonds={bonds} type="all" refresh={refresh}/></TabPanel>);
        // p.push(<TabPanel header="등록채권" key="complete" headerTemplate={faqTabHeaderTemplate}><CaseBody bonds={bonds} type="complete" refresh={refresh}/></TabPanel>);
        // p.push(<TabPanel header="채권등록중" key="incomplete" headerTemplate={faqTabHeaderTemplate}><CaseBody bonds={bonds} type="incomplete" refresh={refresh}/></TabPanel>);

        // p.push(<TabPanel header="진행중사건" key="handling" headerTemplate={faqTabHeaderTemplate}><CaseBody bonds={bonds} type="handling" refresh={refresh}/></TabPanel>);
        p.push(<TabPanel header="입력필요 사건" key="handling" headerTemplate={faqTabHeaderTemplate}><CaseBody bonds={bonds} type="handling" refresh={refresh}/></TabPanel>);
        setPanels(p);
    }, [bonds]);

    const refresh = () => {
        setTick((prev) => prev+1);
    }

    const faqTabHeaderTemplate = (options) => {
        // options.selected
        // options.index
        const bc = classnames("p-2 mx-1 border-round-3xl cursor-pointer text-sm", {
            'orange-back font-bold': options.selected,
            'surface-300 text-600': !options.selected
        });
        return (
            <div className={bc} onClick={options.onClick}>
                {options.titleElement}
            </div>
        );
    }

    return (
        <TabView className="faq-tab-view">
            {panels}
        </TabView>
    );
}

export function isEnrolled(state) {
    return state?.login === "active" && state?.input === "complete"
}

function CaseBody(props) {
    // props.bonds: counsel에 userChat, case 합쳐진 것    
    // props.type: 종류. all, complete, incomplete
    // props.refresh: 삭제하고 refresh하기 위해 
    const [cases, setCases] = useState([]);
    const [nftUrl, setNftUrl] = useState(null);
    const [showNftDialog, setShowNftDialog] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showDone, setShowDone] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    const [addable, setAddable] = useState(false);  // 채팅 추가 가능한가?

    //2311 gary => enroll기준->progress기준으로 변경하려다, 기존코드가 새로읽음기능이 잘되고있어 일단 보류.
    // const [enrolledCount, setEnrolledCount] = useRecoilState(CounselEnrollAtom);
    // const [progressCount, setProgressCount] = useRecoilState(CounselProgressAtom);
    // const addable = (progressCount === 0);
    // console.log('ChatList: progressCount, enrolledCount, addable:' + progressCount + "," +enrolledCount + "," + addable);




    const navigate = useNavigate();

    const showNft = (url) => {
        console.log(`nft url = ${nftUrl}`);
        setNftUrl(url);
        setShowNftDialog(true);
    }

    const deleteChat = (userChatId) => {
        setShowConfirmDelete(true);
        setToDelete(userChatId);  // 기록을 해 둔다. 
        /*
        console.log(`delete userChat=${userChatId}`);
        BoNetUtil.put(`${BACKEND_URL}/api/v1/userChats/${userChatId}`, {isActive: false},
            (resp) => {
                console.log(`UserChat ${userChatId} is deactivated`);
            },
            (err) => console.error(err)
        );
        */
    }

    const doDeleteChat = () => {
        setShowConfirmDelete(false);
        if (!toDelete) return;
        console.log(`delete userChat=${toDelete}`);
        BoNetUtil.put(`${BACKEND_URL}/api/v1/userChats/${toDelete}`, {isActive: false},
            (resp) => {
                console.log(`UserChat ${toDelete} is deactivated`);
                setToDelete(null);
                setShowDone(true);
                props.refresh();
            },
            (err) => console.error(err)
        );
    }

    useEffect(() => {
        if (props.type === "complete") {
            setCases(props.bonds.filter((item) => isEnrolled(item.state)))
        } else if (props.type === "incomplete") {
            setCases(props.bonds.filter((item) => !isEnrolled(item.state)))
        } else if (props.type === "handling") {
            console.log('HANDLING:',props.bonds);
            //bonds=counsel
            // setCases(props.bonds.filter((item) => item.case?.payResult > 0));  // 결제가 완료된 것만
            setCases(props.bonds.filter((item) => item.cmStatus > 0 && item.cmStatus !==2) );  //2=검토중

        } else {  // 전체 
            setCases(props.bonds);
        }
    }, [props.bonds]);

    //2311 gary : enrolledCount->recoil로 변경하려다가 여기서 새로읽음 하게 더 정확해서.. 일단 보류.
     useEffect(() => {
        if (!props.bonds) return;

        const notEnrolled = props.bonds.reduce((acc, curr, idx) => {
            if (!isEnrolled(curr.state)) acc += 1;
            return acc;
        } , 0);

        //console.log(`notEnrolled = ${notEnrolled}`);
        setAddable(notEnrolled === 0);
    }, [props.bonds]);

     const gotoChat = (e) => {
         window.location.href = 'http://pf.kakao.com/_xbDexfG/chat';
         return;
     }

    return (
        <div>
            {
                cases.map((item, index) => {
                    console.log('case:',item);
                    return <EachCase counsel={item} key={index} showNft={() => showNft(item.nftUrl)} deleteChat={() => deleteChat(item.userChatObjectId)}/>
                })
            }
            <br/><br/><br/>
            {window.location?.host.startsWith('localhost') &&
                <OrangeButton label="추가 신청하기OLD" disabled={!addable} onClick={() => navigate('/chat')}/>
            }
            <OrangeButton label="추가 신청하기" onClick={gotoChat}/>

            <Modal open={showNftDialog} center showCloseIcon={false} onClose={() => setShowNftDialog(false)}
                classNames={{modal: "border-round-lg p-1 m-0"}} styles={{modal: {width: "85vw"}}}>
                <div>
                    <img src={nftUrl} className="w-full"/>
                </div>
            </Modal>
            <Modal open={showConfirmDelete} center showCloseIcon={false} onClose={() => setShowConfirmDelete(false)}
                classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}}>
                <div className="text-center font-bold">채권등록을 취소하시겠습니까?</div>
                <br/>
                <div className="grid">
                    <div className="col-6"><OrangeButton label="확인" onClick={() => doDeleteChat()}/></div>
                    <div className="col-6"><WhiteButton label="아니오" onClick={() => setShowConfirmDelete(false)} /></div>
                </div>
            </Modal>
            <Modal open={showDone} center showCloseIcon={false} onClose={() => setShowDone(false)}
                classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}}>
                <div className="text-center font-bold">완료되었습니다.</div>
                <br/>
                <div className=""><OrangeButton label="확인" onClick={() => setShowDone(false)}/></div>
            </Modal>
        </div>
    );
}

function EachCase(props) {
    // props.counsel: counsel object
    // props.showNft
    const {axiosGet, axiosPost} = useAxios();

    //2312 const [enrolled, setEnrolled] = useState(null);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState(0);  // 0 = 등록중, 1=등록채권, 2=진행중사건 
    const [blueLabel, setBlueLabel] = useState("");  // 오른쪽 파란 글씨 
    const [link, setLink] = useState("");
    const navigate = useNavigate();

    const [showVbankInfo, setShowVbankInfo] = useState(false);

    //2312 refactoring todo useEffect 다 제거 가능. /////////////////////
    const enrolled = isEnrolled(props.counsel?.state);
    // const payFinish = props.counsel.userChat?.payFinish;
    const payFinish = props.counsel?.payFinish; //2411
    const deptorName = props.counsel.case?.deptorName;


    // useEffect(() => {
    //     setEnrolled(isEnrolled(props.counsel?.state));
    // }, [props.counsel]);

    useEffect(() => {
        //2312 if (props.counsel.case?.payResult > 0) {
        if (payFinish == 1 || payFinish == -1) { //-1:fake결제
            if (deptorName) {
                setTitle(`진행중 사건 - ${deptorName}(채무자)`);
            } else {
                setTitle(`진행중 사건`);
            }
            setStatus(2);
        } else if (enrolled) {
            if (deptorName) {
                setTitle(`등록채권 - ${deptorName}(채무자)`);
            } else {
                setTitle(`등록채권`);
            }
            setStatus(1);
        } else {
            setTitle("채권등록중");
            setStatus(0);
        }
    }, [props.counsel]);

    useEffect(() => {
        //2312 let payFinish = props.counsel.case?.payResult;
        /**
         * counsel: {...counsel, case, userCHat, state, email, }
         */
        //const enrolled = isEnrolled(props.counsel?.state);
        if (!enrolled) {
            setBlueLabel("계속 등록하기 >");
            console.log('goToLink(chatListPage 296):');
            setLink(`/chat/${props.counsel.userChatObjectId}`);
        } else {
            if (payFinish > 0 || payFinish == -1) { //-1 : fake결제
                if (payFinish >= 2) { //가상계좌 입금 대기. 3=입금오류.
                    setBlueLabel("가상계좌 입금대기");
                    setLink("");
                } else { //입금완료
                    setBlueLabel("사건배정 대기중");
                    setLink("");

                    //cm에 gubun/요건사실유형 있으면, 스토리텔링 입력필요 로 전환.
                    //2411 checkCreditMaster(props.counsel._id);
                    let cmStatus = props.counsel.cmStatus;
                    if (cmStatus > 0) {
                        setBlueLabel("사건정보 입력필요 >");
                        if (cmStatus == 4) { //2409 전체 재질문 추가.
                            setBlueLabel("사건정보 재입력필요 >");
                        }
                        setLink(`/desk/storyTelling/${props.counsel._id}`);

                        if (cmStatus == 2) {
                            setBlueLabel("사건정보 검토중");
                            setLink(``);
                        }else if (cmStatus == 3) {
                            setBlueLabel("사건정보 추가입력필요 >");
                            setLink(`/desk/storyReRequest/${props.counsel._id}`);
                        }

                    }



                }
            } else {
                setBlueLabel("결제하기(90%할인) - 한시적 혜택 >");
                const c = props.counsel.case;
                if (!!!c?.pricePlan?.deposit) {
                    setLink(`/chat/${props.counsel.userChatObjectId}`);    
                } else if (!!!c?.creditorsSize) {
                    setLink(`/creditor/${props.counsel.userChatObjectId}`);    
                } else if (!!!c?.debtorsSize) {
                    setLink(`/debtor/${props.counsel.userChatObjectId}`);    
                } else {
                    setLink(`/payment/${props.counsel.userChatObjectId}`);
                }                
            }
        }        
    }, [props.counsel]);


    //todo 2411- backend DTO로 이동. -> 0보다 크고 2가 아니면: 입력필요사건임.
    const checkCreditMaster = async (counselObjectId) => {

        // let cmCount = await axiosGet(`/api/ipoUser/creditMaster/count/${counselObjectId}`);
        let cmStatus = await axiosGet(`/api/ipoUser/creditMaster/check/${counselObjectId}`);
        // console.log(' checkCreditMaster', cmCount, counselObjectId);
        console.log(' checkCreditMaster', cmStatus, counselObjectId);

        //if cm exists : counselId로 체크필요.
        // if (cmCount > 0) {
        if (cmStatus > 0) {
            setBlueLabel("사건정보 입력필요 >");
            if (cmStatus == 4) { //2409 전체 재질문 추가.
                setBlueLabel("사건정보 재입력필요 >");
            }
            setLink(`/desk/storyTelling/${counselObjectId}`);

            if (cmStatus == 2) {
                setBlueLabel("사건정보 검토중");
                setLink(``);
            }else if (cmStatus == 3) {
                setBlueLabel("사건정보 추가입력필요 >");
                setLink(`/desk/storyReRequest/${counselObjectId}`);
            }

        }
    }

    const gotoLink = async () => {
        console.log('goToLink(chatListPage 318):', link);
        if (link) {
            //deskTop에서 스토리텔링 입력. - 새로운창으로 실행해야 화면 resize가 됨. + 재요청입력
            if (link.includes('/desk/')) {
                window.open(link, "_blank");

                let resInt = await axiosPost(`/api/ipoUser/askFlow/ipoStart/${props.counsel._id}`);
                console.log('ipoStart:', resInt);
            }
            else {
                navigate(link);
            }
        }
    }

    return (
        <div className="w-full">
            <hr/>
            <div className="px-2">
                <div className="text-left flex align-items-center cursor-pointer">
                    <div className={classnames("text-lg font-bold", {"text-500": !enrolled})} style={{color: payFinish==1?"#FCD462":"#0A174E"}} onClick={() => navigate(`/chat/${props.counsel.userChatObjectId}`)}>
                        {title}
                    </div>
                    <div className="ml-2">
                        { props.counsel.nftUrl 
                            ? <img src="/assets/ico_cache.png" style={{height:"2rem"}} className="cursor-pointer" onClick={() => props.showNft(props.counsel.nrtUrl)}/>
                            : null
                        }
                    </div>
                </div>
                <div className="mt-1 w-full flex align-items-center justify-content-between">
                    <div>
                        { props.counsel.moneySize && props.counsel.moneySize > 0 
                        ? <div onClick={() => navigate(`/chat/${props.counsel.userChatObjectId}`)}><span className="text-xl font-bold">{commaNumber(props.counsel.moneySize)}</span><span className="font-bold">원</span></div>
                        : "-"
                        }
                    </div>
                    <div className="cursor-pointer" onClick={ (payFinish===2)? ()=>setShowVbankInfo(true) : gotoLink}>
                        <span className="text-xs" style={{color: "#4B85F0"}}>{blueLabel}&nbsp;&nbsp;{payFinish===1?"":""}</span>
                    </div>
                </div>
                <div className="text-left">
                    <div className="text-500 text-sm">
                        { //props.counsel.case?.payResult === 0 ?
                           props.counsel.payFinish === 1 || props.counsel.payFinish === -1 ? //-1은 챗봇 test용
                        <>
                            <span>최초작성일: </span>
                            <span>{dateFormat(props.counsel.createdAt, 'yy.mm.dd')}</span>
                            <span> | 최종작성일: </span>
                            <span>{dateFormat(props.counsel.modifiedAt, 'yy.mm.dd')}</span>
                        </>
                        : <span>&nbsp;</span>
                        }
                    </div>
                </div>
                <div style={{width: "4rem"}} className="hidden">
                    <Button icon="pi pi-times" rounded text severity="secondary" className="surface-200 font-bold" onClick={props.deleteChat}/>
                </div>
            </div>

            <Modal open={showVbankInfo} center showCloseIcon={false} onClose={() => setShowVbankInfo(false)}
                   classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}}>
                <div className="text-center font-bold"> 입금은행: {props.counsel.userChat?.bankName}</div>
                <div className="text-center font-bold"> 계좌번호: {props.counsel.userChat?.accountNum}</div>
                <div className="text-center font-bold"> 예금주: {props.counsel.userChat?.accountOwner}</div>
                <br/>
                <div className=""><OrangeButton label="확인" onClick={() => setShowVbankInfo(false)}/></div>
            </Modal>

        </div>
    );
}