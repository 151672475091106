import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BoNetUtil } from "../common/Util";
import { useRecoilValue } from "recoil";
import { BACKEND_URL } from "../Atoms";
import { OrangeButton } from "../common/Common";
import useAxios from "../hooks/useAxios";

export function PaymentCallback(props) {
    const { oid } = useParams();

    const [result, setResult] = useState(null);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    const {axiosGet, axiosPost} = useAxios();

    useEffect(() => {
        console.log(`PAYMENT CALLBACK!!!, OID=${oid}`);
        // vws/bo/payment/result?orderNo=20231117140707926PoVbdt
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/payment/result`, {orderNo: oid},
            (resp) => {
                setResult(resp.data);
                if (resp.data?.status === "00" || resp.data?.status === "0000") {

                    if (resp.data.payMethod === 'VBANK') {
                        setMessage(`<div>아래 가상계좌로 10일내에 입금해 주세요 <br/><br/> 은행명: ${resp.data.bankName} <br/> 계좌번호: ${resp.data.accountNum} <br/> 예금주: ${resp.data.accountOwner}</div>`);
                    }
                    else {
                        setMessage("결제에 성공하였습니다.");
                    }

                } else {
                    setMessage(`${resp.data?.status}: ${resp.data?.message}`);
                }
            },
            (err) => console.error(err)
        );
    }, [oid]);


    //대여금 preBond predata입력/결제 페이지로 돌아가기.
    const goToPbsUrl = async () => {
        //todo get preBondId -> goto /pbs/${preBondId}
        let res = await axiosGet(`/api/v1/prebonds/afterPaySuccess/tempOid/${oid}`)
        if( res && res.code > 0) { //조회성공시 1로 리턴

            navigate(`/pbs/${res.msg}`);

        }else { //대여금 이전 결제용. (대여금 배포 이후는 미사용일듯)
            navigate("/");
        }


    }

    return (
        <div className="px-3">
            <br/><br/><br/><br/><br/><br/>
            {/*<div className="text-lg">{message}</div>*/}
            <div className="text-lg" dangerouslySetInnerHTML={{ __html: message }}></div>
            <br/><br/>
            {/*<OrangeButton label="확인" onClick={() => navigate("/")}/>*/}
            <OrangeButton label="확인(추가정보 입력)" onClick={goToPbsUrl}/>
        </div>
    );
}