import { useRecoilValue } from "recoil";
import { GrayLine, OrangeButton, WhiteTopBar } from "../common/Common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL, BoUserIdAtom, UserObjectIdAtom } from "../Atoms";
import { BoNetUtil, isDevEnv } from "../common/Util";
import commaNumber from "comma-number";
import { Checkbox } from 'primereact/checkbox';
import { Modal } from 'react-responsive-modal';
import {isMobile} from 'react-device-detect';
import {Dropdown} from "primereact";
import {Space, Spin} from "antd";
import {Flex, FlexCenter, FlexColumn} from "../common/DeskComponent";
import useAxios from "../hooks/useAxios";
import { useLocation } from 'react-router-dom';

export function PaymentPage(props) {
    const location = useLocation();

    console.log('PaymentPage.js0: state caseInfo', location.state);
    const userObjectId = useRecoilValue(UserObjectIdAtom);

    const [userChatState, setUserChatState] = useState(null);  // data 아래의 항목임. 
    const [caseInfo, setCaseInfo] = useState(location.state?.caseInfo);
    const [showInvalid, setShowInvalid] = useState(false);
    const { chatId } = useParams();

    useEffect(() => {
        console.log(`### chaitID useEffect caseInfo : Get existing userChat ${chatId}`);
        // resp: {"userChatObjectId":"654b9cf1e88ae696d7d1aaab"}
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${chatId}`, null,
            (resp) => {
                // 다른 사용자 것이 아닌지 체크한다. : 결제 TEST시에 4줄 comment하면 됨.
                if (resp.data.data[0].userObjectId !== userObjectId) {
                    console.warn("Invalid chat-user pair");
                    setShowInvalid(true);
                } else
                {
                    setUserChatState(resp.data.data[0]);
                    // case 번호 얻기 
                    BoNetUtil.get(`${BACKEND_URL}/vws/bo/case/info`, {chatObjectId: chatId},
                        (resp) => {
                            setCaseInfo(resp.data);
                            console.log('###SET CASE INFO:',resp.data);

                            console.log(`Case Info = caseNo=${resp.data.caseNo}, plan=${resp.data.pricePlan?.planType}`);
                        },
                        (err) => console.error(err)
                    );
                    //console.log("ChatState=");
                    //console.dir(resp.data);
                }
            },
            (err) => {
                console.error(err);
                setShowInvalid(true);
            }   
        );        
    }, [chatId]);

    return (
        <>
        { !!showInvalid
            ? <div className="text-center font-bold">잘못된 상담정보입니다.</div>
            :
            <div className="px-2">
                <WhiteTopBar title="결제"/>
                <GrayLine/>
                <br/>
                <PaymentContent caseInfo={caseInfo}/>
            </div>
        }
        </>
    );
}

function PaymentContent(props) {
    console.log('PaymentContent.js1: props caseInfo', props);
    // props.caseInfo
    const [planName, setPlanName] = useState("?");
    const [planDesc, setPlanDesc] = useState("");

    useEffect(() => {
        if (!props.caseInfo) return;
        const names = ["A", "B", "C", "D", "E", "F"];
        const last = props.caseInfo?.pricePlan?.planType.slice(-1);
        if (last) {
            setPlanName(names[Number(last)-1]);
        } else {
            setPlanName("?");
        }
        setPlanDesc(props.caseInfo?.pricePlan?.planDescription || "?????");
    }, [props.caseInfo]);
    return (
        <div>
            <div className="text-lg">선택하신 플랜으로 결제 및 계약을 진행합니다.</div>
            <br/>
            <GrayLine/>
            <div className="p-3">
                <div className="text-left">결제 플랜</div>
                <div className="border-1 border-300 p-3 text-left mt-3">
                    <div className="font-bold">결제 플랜 {planName}</div>
                    <div className="mt-2">{planDesc}</div>
                </div>
                <br/>
                <br/>
                <div>
                    <div className="flex">
                        <div>수임료</div>
                        <div className="ml-auto">{commaNumber(props.caseInfo?.pricePlan?.deposit)} 원</div>
                    </div>
                    <div className="flex mt-2">
                        <div>부가가치세(10%)</div>
                        <div className="ml-auto">{commaNumber(props.caseInfo?.pricePlan?.deposit*0.1)} 원</div>
                    </div>
                    <hr/>
                    <div className="flex">
                        <div>총 결제 금액(VAT포함)</div>
                        <div className="ml-auto">{commaNumber(props.caseInfo?.pricePlan?.deposit*1.1)} 원</div>
                    </div>
                </div>
                <br/>
                <GrayLine/>
                <br/>
                <div>
                    <AgreeTerms caseInfo={props.caseInfo}/>
                </div>
            </div>
        </div>
    );  
}


const B2cContractIpo = ({caseNo}) => {

    const [pdfUrl, setPdfUrl] = useState();
    const {axiosGet} = useAxios();

    useEffect(()=> {

        firstSearch();

    },[]);

    const firstSearch = async() => {

        let res = await axiosGet(`/vws/bo/payment/contractIpo/${caseNo}`);

        console.log('##ContractIPO: res=',res);

        if (res && res.code > 0) {
            setPdfUrl(res.msg);

        }else {
            console.log('##ContractIPO 오류: res=',res);
        }
    }

    if(!pdfUrl) {
        return <FlexCenter>
                <Spin/>
            </FlexCenter>

    }

    return (
        <iframe src={pdfUrl} style={{width:'100%', height:'370px'}}>

        </iframe>
    )
}

function AgreeTerms(props) {
    console.log('PaymentPage.js1: props caseInfo', props.caseInfo);
    //mobile
    const [selectedPay, setSelectedPay] = useState();
    const pays = [
        { name: '신용카드', code: 'CARD' },
        // { name: '무통장입금', code: 'VBANK' },
        { name: '계좌이체', code: 'BANK' },
    ];
    //pc
    const [selectedPcPay, setSelectedPcPay] = useState();
    const pcPays = [
        { name: '신용카드', code: 'Card' },
        { name: '무통장입금', code: 'VBank' },
        { name: '계좌이체', code: 'DirectBank' },
    ];

    // const SERVER_ADDR = (window.host === 'creditncity-c.com')? 'https://creditncity-c.com' : 'https://vws.ai';
    const SERVER_ADDR = BACKEND_URL;//(window.host === 'vws.ai')? 'https://vws.ai': 'https://creditncity-c.com';
    console.log('PaymentCB SERVER', SERVER_ADDR);

    // props.caseInfo
    const [all, setAll] = useState(false);
    const [term, setTerm] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [push, setPush] = useState(false);
    const [age, setAge] = useState(false);
    const [mandate, setMandate] = useState(false);  // 위임계약
    const [sawMandate, setSawMandate] = useState(false);  // 위임계약 봤나?
    const [pay, setPay] = useState(false);  // 결제 진행 동의?
    const [payable, setPayable] = useState(false);  // 결제 가능한가?
    const [showMandate, setShowMandate] = useState(false);  // 위임계약서를 보여줄 것인가? 
    const [mandateContent, setMandateContent] = useState(null);  // 위임계약서 내용 
    const [payInfo, setPayInfo] = useState(null);  // /vws/bo/payment/req  의 응답. 결제에 필요한 MID, OID 등 가져옴. 
    /*
    const [mid, setMid] = useState("INIpayTest");
    const [oid, setOid] = useState("mobile_test1234");  //TODO: 수정 필요 
    const [price, setPrice] = useState(1000);  //TODO: 수정 필요 
    const [goods, setGoods] = useState("테스트상품");
    const [userName, setUserName] = useState("이재규");
    const [phone, setPhone] = useState("01045475042");
    const [email, setEmail] = useState("leejaku@gmail.com");
    */

    const boUserId = useRecoilValue(BoUserIdAtom);
    const navigate = useNavigate();
    const mobilePayForm = useRef(null);
    const pcPayForm = useRef(null);
    const [divider, setDivider] = useState(1);

    const selectAll = (e) => {
        setAll(e.checked);
        setTerm(e.checked);
        setPrivacy(e.checked);
        setPush(e.checked);
        setAge(e.checked);  
        if (e.checked === true) {      
            if (sawMandate === true) setMandate(e.checked);
        } else {
            setMandate(e.checked);
        }
        setPay(e.checked);
    }

    useEffect(() => {
        setPayable(enablePayment());
    }, [term, privacy, age, mandate, pay]);

    useEffect(() => {
        console.log('PaymentPage.js1: caseInfo.caseNo:', props.caseInfo?.caseNo);

        if (!props.caseInfo) return;
        console.log('PaymentPage.js2: caseInfo.caseNo:', props.caseInfo?.caseNo);
        // vws/bo/docs/case/144/contract
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/docs/case/${props.caseInfo.caseNo}/contract`, null,
            (resp) => {
                setMandateContent(resp.data);
            },
            (err) => console.error(err)
        );
    }, [props.caseInfo]);

    // 결제 필요 정보 가져오기
    useEffect(() => {
        if (!props.caseInfo) return;


        console.log(`IsMobile =====> ${isMobile}`);
        if (isMobile) {
        //if (false) {
            BoNetUtil.get(`${BACKEND_URL}/vws/bo/payment/req/${props.caseInfo.caseNo}/mobile`, null,
                (resp) => {
                    setPayInfo(resp.data);
                    if (isDevEnv()) setDivider(1000);  // 개발을 위해서 액수를 줄임 
                },
                (err) => console.error(err)
            );
        } else {
            BoNetUtil.get(`${BACKEND_URL}/vws/bo/payment/req/${props.caseInfo.caseNo}/pc`, null,
                (resp) => {
                    setPayInfo(resp.data);
                    //if (isDevEnv()) setDivider(100);  // PC 버전은 금액을 해시로 비교하기 때문에 나누지 않음. 
                    //setDivider(100);  // TEMP
                },
                (err) => console.error(err)
            );
        }
    }, [props.caseInfo]);

    const doPay = (e) => {
        if (isMobile) {
        //if (false) {
            mobilePayForm.current.action = "https://mobile.inicis.com/smart/payment/";
            mobilePayForm.current.target = "_self";
            mobilePayForm.current.submit(); 
        } else {
            const agt = navigator.userAgent.toLowerCase();
            const script = document.createElement("script");
            script.src = payInfo?.mid === "INIpayTest" ? "https://stgstdpay.inicis.com/stdjs/INIStdPay.js" : "https://stdpay.inicis.com/stdjs/INIStdPay.js";
            document.head.appendChild(script);
            script.onload = e => {
                if ( (navigator.appName === 'Netscape' && agt.indexOf('trident') !== -1) || (agt.indexOf("msie") !== -1) ||  agt.indexOf('edge')) {
                    //
                    e.target.ownerDocument.defaultView.INIStdPay.pay('SendPayForm_id');
                } else{
                    e.path[3].defaultView.INIStdPay.pay('SendPayForm_id');
                }
            }
        }
    }

    const onAgreeMandate = () => {
        BoNetUtil.put(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, {contract: true}, // 동의여부 없어서?
            (resp) => {
                console.log("mandate = %o", resp.data);
                setShowMandate(false);
                setMandate(true);
                setSawMandate(true);
            },
            (err) => {
                console.error(err)
            }
        );
    }

    const enablePayment = () => {
        return term === true && privacy === true && age === true && mandate === true && pay === true;
    }

    const changePay = (e) => {
        console.log('chagnePay', e.value.name);
        console.log('chagnePay', e.value.code);
        setSelectedPay(e.value);

        console.log('setSelectedPay', selectedPay);
    }
    const changePcPay = (e) => {
        setSelectedPcPay(e.value);

        console.log('setSelectedPcPay', selectedPcPay);
    }

    return (
        <div className="px-3 text-left">
            <div className="my-3">
                <Checkbox checked={all} onChange={selectAll}/>
                <label className="px-2 font-bold">전체동의</label>            
            </div>
            <hr/>
            <div className="flex align-items-center my-3">
                <Checkbox checked={term} onChange={(e) => setTerm(e.checked)}/>
                <label className="px-2 text-700">[필수] 이용약관에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/terms")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={privacy} onChange={(e) => setPrivacy(e.checked)}/>
                <label className="px-2 text-700">[필수] 개인정보 수집 및 이용에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/privacy")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={push} onChange={(e) => setPush(e.checked)}/>
                <label className="px-2 text-700">[선택] 정보 안내 수신에 동의</label>
                {/*<i className="pi pi-angle-right ml-auto px-2"/>  /!* TODO: 이건 어디로 링크?? *!/*/}
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={age} onChange={(e) => setAge(e.checked)}/>
                <label className="px-2 text-700">[필수] 본인은 만 14세 이상입니다.</label>
                {/*<i className="pi pi-angle-right ml-auto px-2"/>*/}
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={mandate} onChange={(e) => setMandate(e.checked)} readOnly={!sawMandate}/>
                <label className="px-2 text-700">[필수] 위임 계약에 대한 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => setShowMandate(true)}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={pay} onChange={(e) => setPay(e.checked)}/>
                <label className="px-2 text-700">[필수] 구매조건 확인 및 결제진행 동의</label>
                {/*<i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/privacy")}/>*/}
            </div>
            <br/>


            {isMobile &&
                <Dropdown className="my-1" value={selectedPay} onChange={changePay} options={pays} optionLabel="name"
                          placeholder="결제방식 선택"/>
            }
            {//!isMobile && //todo 1. 이 블록 오픈.
            // <Dropdown className="my-1" value={selectedPcPay} onChange={changePcPay} options={pcPays} optionLabel="name"
            //           placeholder="결제방식 선택"/>
            }

            {isMobile && //todo 2. 이라인만 제거. 3. 밑에 block제거. 4. CreditorPage.js 제거
                <OrangeButton label="결제하기" onClick={doPay} disabled={!payable}/>
            }
            {!isMobile &&
                <div>pc결제 점검 중입니다. 휴대폰으로 결제해 주세요.</div>
            }

            <Modal open={showMandate} center showCloseIcon={true} onClose={() => setShowMandate(false)}
                classNames={{modal: "border-round-lg pb-0"}} blockScroll={false}
                styles={{modal: {width: "85vw", height:"32rem", overflowY: "scroll", overflowX: "hidden", overflowScrolling: "touch",
                WebkitOverflowScrolling: "touch"}}}>  {/* iphone x 에서 스크롤 안되엇 */}
                <div className="text-center font-bold">위임계약에 대한 동의</div>
                <br/>
                {isDevEnv()?
                    <B2cContractIpo caseNo={props.caseInfo?.caseNo}/>
                    :
                    <div dangerouslySetInnerHTML={{__html: mandateContent}}>
                    </div>
                }

                <br/>
                <div className="">
                    <OrangeButton label="동의" onClick={onAgreeMandate}/>
                </div>
                <br/>
            </Modal>
            <div className="hidden">
                {/* 결제 Form*/}
                <form ref={mobilePayForm} method="POST" acceptCharset="EUC-KR">
                    <input type="hidden" name="P_INI_PAYMENT" value={(selectedPay && selectedPay.code)?selectedPay.code : 'CARD'}/>

                    <input type="hidden" name="P_MID" value={payInfo?.mid}/>
                    <input type="hidden" name="P_OID" value={payInfo?.oid}/>
                    <input type="hidden" name="P_AMT" value={payInfo?.pricePlan?.deposit ? (payInfo.pricePlan.deposit*1.1)/divider : 0}/>
                    <input type="hidden" name="P_GOODS" value={payInfo?.pricePlan?.planDescription || "채권 회수 플랜"}/>
                    <input type="hidden" name="P_UNAME" value={payInfo?.uname}/>
                    <input type="hidden" name="P_MOBILE" value={payInfo?.mobile}/>
                    <input type="hidden" name="P_EMAIL" value={payInfo?.email}/>
                    <input type="hidden" name="P_NEXT_URL" value={SERVER_ADDR + "/cncc/cb/inicis/mobile"}/>
                    <input type="hidden" name="P_NOTI_URL" value={SERVER_ADDR + "/cncc/cb/inicis/vbankmobile"}/>
                    <input type="hidden" name="P_CHARSET" value="utf8"/>
                    <input type="hidden" name="P_RESERVED" value="below1000=Y&vbank_receipt=Y&centerCd=Y"/>
                    <input type="hidden" name="P_NOTI" value={payInfo?.oid}/> {/* 결제 실패시, OID를 내려줘서 여기에 담음 */}
                </form>
                <form ref={pcPayForm} id="SendPayForm_id" method="POST" acceptCharset="UTF-8">
                    <input type="hidden" name="version" value="1.0"/>
                    {/*<input type="hidden" name="gopaymethod" value="Card:DirectBank:VBank"/>*/}
                    <input type="hidden" name="gopaymethod" value={(selectedPcPay && selectedPcPay.code)?selectedPcPay.code : 'Card'}/>
                    <input type="hidden" name="mid" value={payInfo?.mid}/>
                    <input type="hidden" name="oid" value={payInfo?.oid}/>
                    <input type="hidden" name="price" value={payInfo?.pricePlan?.deposit ? (payInfo.pricePlan.deposit*1.1)/divider : 0}/>
                    <input type="hidden" name="timestamp" value={payInfo?.timestamp}/>
                    <input type="hidden" name="use_chkfake" value="Y"/>
                    <input type="hidden" name="signature" value={payInfo?.signature}/>
                    <input type="hidden" name="verification" value={payInfo?.verification}/>
                    <input type="hidden" name="mKey" value={payInfo?.mkey}/>
                    <input type="hidden" name="currency" value="WON"/>
                    <input type="hidden" name="goodname" value={payInfo?.pricePlan?.planDescription || "채권 회수 플랜"}/>
                    <input type="hidden" name="buyername" value={payInfo?.uname}/>
                    <input type="hidden" name="buyertel" value={payInfo?.mobile || "0101234567"}/>
                    <input type="hidden" name="buyeremail" value={payInfo?.email}/>
                    <input type="hidden" name="returnUrl" value={SERVER_ADDR + "/cncc/cb/inicis/pc"}/>
                    <input type="hidden" name="closeUrl" value={SERVER_ADDR + "/payclose"}/>
                    <input type="hidden" name="acceptmethod" value="HPP(1):below1000:centerCd(Y)"/>
                    <input type="hidden" name="merchantData" value={payInfo?.oid}/> {/* 결제 실패시, OID를 내려줘서 여기에 담음 */}
                </form>
            </div>
        </div>
    )
}