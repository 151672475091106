import React, {useState, useEffect} from "react";
import {LayoutWrapper, Header, Footer, Content, GrayLine, GrayDiv, FlexColumn, LNB, MainMenu, SubMenu} from "../../common/B2bComponent";
import {Space} from "antd";
import {useNavigate} from "react-router-dom";


//backend B2bStatus와 동일.
export const B2bStatus = {
    USER_SIGNUP_WAITING:  0,
    ADMIN_SIGNUP_CONFIRM: 1,    //=회원가입완료. 사용자 predata waiting 상태
    // USER_PREDATA_INPUT_DONE: 2, // 프리데이터 입력완료
    // ADMIN_PREDATA_CONFIRM: 3,   //= 관리자 plan세팅 완료
    // USER_PLAN_CONFRIM_OK:  4,    //사용자의 계약 완료, account테이블에만 존재.
    // ADMIN_CONTRACT_SEND_OK: 5,   //관리자의 계약서 이메일 송부 완료, account테이블에만 존재.
}


const B2bLayout = ({title, children, showLNB}) => {

    const navigate = useNavigate();


    //1. Layout깜빡임 제거 1.
    const [child, setChild] = useState(children);

    const [childName, setChildName] = useState();



    const openPopup = (url) => {
        window.open(url, '팝업', 'width=800,height=600,toolbar=no,location=no');
    }



    return (
        <LayoutWrapper>
            <FlexColumn>
                <Header>
                    <img onClick={()=>{navigate('/')}} style={{width:130, height:'auto', marginTop:5, marginBottom:5, marginLeft:5, marginRight:10}} src="/assets/logoB2ai.png" alt="Logo" />
                    {/*<h4s>{title}</h4s>*/}
                    <div style={{fontSize:'14px'}}><b> {title? title:'채권 2AI 기업회원'} </b></div>
                </Header>
                <GrayLine/>
            </FlexColumn>

            {!showLNB && //로그인관련 화면들.
            <Content>{children}</Content>
            }


            <Footer>
                <GrayLine/>
                <Space>
                    <GrayDiv onClick={()=> openPopup('/terms')}> 이용약관 </GrayDiv>
                    <GrayDiv  onClick={()=> openPopup('/privacy')}> 개인정보처리방침 </GrayDiv>
                    <div style={{marginRight:30}}> </div>
                </Space>
            </Footer>
        </LayoutWrapper>
    )
}

export default B2bLayout;