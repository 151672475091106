import React, {useEffect, useState, useRef} from "react"

import useAxios from "../../hooks/useAxios";
import EndorseLayout from "./EndorseLayout";
import {Button, Flex, message, Modal, Space, Table} from "antd";
import {EmptyBox, FlexCenter, Title2} from "../../common/B2bComponent";
import {BACKEND_URL, BoTokenAtom, BoUserIdAtom, BoUserTypeAtom} from "../../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import {getCmField} from "../../common/CmUtil";
import {addComma} from "../../common/Util";
import {FlexColumn} from "../../common/DeskComponent";
import PdfViewer from "../etc/PdfViewer"

const Endorsement = () => {

    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);       //위 boToken에서 파생.
    const boUserType  = useRecoilValue(BoUserTypeAtom);  //anonymous  google kakao naver  b2b추가됨.

    const [userInfo, setUserInfo] = useState();
    const [samchully, setSamchully] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [docList, setDocList] = useState([]);
    const [pdfUrl, setPdfUrl] = useState();
    const refOutputPdfViewer = useRef();

    const {axiosGet, axiosPost} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();


    useEffect(() => {
        console.log('B2bMain- USE_EFFECT []:' + BACKEND_URL);
        console.log('B2bMain- USE_EFFECT []: boUserId:' + boUserId);
        console.log('B2bMain- USE_EFFECT []: boUserType:' + boUserType);

        //1. 로그인 필요.
        if (!boUserId || boUserType !== 'b2b') { //email로그인 체크 강화 필요. boUserType을 이용하던지.
            // 로그인이 안되어 있으면. login화면으로 이동.
            window.location.href = '/endorsement/loginLanding';
        }else {
            //로그인 완료. 아래에서 처리.
            //userName필요.

            getUserName();
        }
    }, []);



    const getUserName = async () => {
        let userInfo = await axiosGet(`/vws/bo/user/info`, {username: boUserId});
        console.log('After B2B signin: userInfo2', userInfo);

        if (userInfo?.corpName.indexOf('삼천리') > 0 || userInfo?.email.indexOf('samchully') > 0) {
            setIsLoading(true);
            //list
            let list = await axiosGet(`/api/b2b/2ai/samchully/endorseList`);

            console.log('list', list);

            setIsLoading(false);
            setSamchully(true);

            //위임장승인 필드를 인위적으로 추가해서 관리.
            setDocList(list.map(  (cm) => ({...cm,  위임장승인:getCmField(cm.원장, '원장.채무자.위임장승인')})));
        }

        setUserInfo(userInfo)
    }

    const makePdfUrl = async(cm) => {
        const docFile = getCmField(cm.원장, '원장.가스요금T.진행T.위임장파일');
        console.log('docFile:', docFile);

        if (docFile && docFile?.url.indexOf('.docx') > 0) {

            // let pdfUrl = docFile.url.replace('.docx', '.pdf'); preSign필요.

            let pdfUrl = await axiosGet(`/api/b2b/2ai/makePdfPresignedUrl/${cm.cmId}`)
            console.log('pdfUrl:', pdfUrl)
            setPdfUrl( pdfUrl );

        }else {
            alert('위임장오류. docx문서 없음 : ' + cm.cmId);
        }
    }

    const signAndRunNextIpo = async(cm) => {

        let res = await axiosPost(`/api/b2b/2ai/signAndRunNextIpo/${cm.cmId}`)
        console.log('signAndRunNextIpo res:', res);

        if (res?.code >=0 ) {
            let 채무자명 = getCmField(cm.원장, '원장.채무자.이름');

            setDocList(prev => {
                return prev.map( (doc) => doc.cmId === cm.cmId?{...doc, 위임장승인:1} : doc );
            })

            messageApi.info(채무자명 + ' 위임장 서명완료');
        }else {
            messageApi.info(' 서명오류');
        }
    }


    const columns = [
        {
            title: <div style={{textAlign:'center'}}> 채무자 </div>,
            key: '원장', dataIndex: '원장',
            render: (text, record, index) => <div> {getCmField(record.원장, '원장.채무자.이름')} </div>,
            width: 150,
        },
        {
            title: <div style={{textAlign:'center'}}> 채권액 </div>,
            key: '원장', dataIndex: '원장',
            render: (text, record, index) => <div
                style={{textAlign: 'right'}}> {addComma(getCmField(record.원장, '원장.predata.moneySize'))} 원</div>,
            width: 100,
        },
        {
            title: <div style={{textAlign:'center'}}> 작업 </div>,
            key: 'payAmount', dataIndex: 'payAmount',
            render: (text, record, index) => <FlexCenter><Flex><Space>
                <Button size={'small'} type={'default'} onClick={()=>makePdfUrl(record)}> 위임장 보기 </Button>

                {record.위임장승인? //getCmField(record.원장, '원장.채무자.위임장승인')?
                    <Button size={'small'} disabled={true} style={{backgroundColor:'lightgray'}}> 서명완료 </Button>
                    :
                    <Button size={'small'} type={'primary'} onClick={()=>signAndRunNextIpo(record)}>  서명하기 </Button>
                }

            </Space></Flex></FlexCenter>,
            width: 400,
        },
    ]


    return(
        <>
            {contextHolder}
            <EndorseLayout showLNB={false} >
                { boUserId && boUserType== 'b2b' &&
                    <>
                        <Title2> {userInfo?.corpName} 위임계약서 </Title2>

                        {samchully && //삼천리 전용.
                            <FlexCenter>

                                <Table style={{width:'90%'}} size={'small'} dataSource={docList} columns={columns}
                                    //onChange={handleTableChange} //pagination={{current:page, pageSize:10}}
                                    pagination={false}
                                    loading={{spinning: isLoading, tip: "Loading..."}}/>

                            </FlexCenter>
                        }
                    </>
                }

            </EndorseLayout>


            <Modal title={` 위임계약서`} width={800} footer={null}
                   open={pdfUrl}
                   onClose={() => setPdfUrl(undefined)}
                   onCancel={() => setPdfUrl(undefined)}
                   destroyOnClose={true}
            >
                <PdfViewer file={pdfUrl}
                           //onLoadSuccess={(count) => refOutputPdfPagination.current.setPageCount(count)}
                           ref={refOutputPdfViewer}
                />

            </Modal>
        </>
    )

}


export default Endorsement;