import React, {useState, useEffect, useRef} from "react";
import {Button, Space, Input, Form, message} from "antd";
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import {Flex, FlexCenter, FlexColumn, FlexColumnCenter, TitleBox, GrayDiv, GrayDivLink} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import EndorseLayout, {B2bStatus} from "./EndorseLayout";
import axios from "axios";
import {BACKEND_URL, BoTokenAtom, BoUserIdAtom, BoUserTypeAtom, UserObjectIdAtom} from "../../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "../../hooks/useAxios";


const EndorseLogin = (props) => {

    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);                        //위 boToken에서 파생.
    //boUserType에 'b2b' 추가 시도 중.
    const [boUserType, setBoUserType]  = useRecoilState(BoUserTypeAtom);  //anonymous  google kakao naver

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();
    const [loginTryCount, setLoginTryCount] = useState(0);


    const navigate = useNavigate();

    const emailInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        /** FIXME  refresh토큰이 오류가 있는것 같아서 일단 코멘트: 재로그인 허용: gary 2401, B2bHome 56라인에서 이쪽으로 넘어오게 해놓음.
        if (boUserId && boUserType === 'b2b') {
            alert('이미 로그인된 상태입니다');
            navigate('/b2b');
            return;
        }
        */

        emailInputRef.current.focus();
    }, []);

    const onLoginFormFinish = (values) => {
        // console.log('onLoginFormFinish:', values);
        setLoading(true);
        const userInfoPromise = doB2BSigninAndGetUserInfo(values);
        userInfoPromise
            .then((userInfo) => {
                // 1.predata 미입력자는 b2bPredata로.
                if (userInfo && userInfo.b2bStatus < B2bStatus.ADMIN_SIGNUP_CONFIRM) {
                    navigate('/endorsement/signupWait');
                } else {
                    navigate('/endorsement');
                }
            })
            .catch((error) => {
                console.log(error);
                alertLoginFailed();
            })
    }

    const onLoginFormFinishFailed = (errorInfo) => {
        console.log('onLoginFormFinishFailed:', errorInfo);
    }

    const alertLoginFailed = () => {

        //alert('로그인에 실패했습니다.\n이메일 또는 비밀번호를 확인해주세요.')
        if (loginTryCount < 1) {
            messageApi.info('다시 로그인 버튼을 눌러주세요');
        }else {
            messageApi.info('로그인에 실패했습니다.\n이메일 또는 비밀번호를 확인해주세요.');
        }

        setLoginTryCount(prev => prev+1);

        setLoading(false);
    }

    const doB2BSigninAndGetUserInfo = async (credential) => {
        let {data} = await axios.post(`${BACKEND_URL}/vws/bo/auth/email/signin`, {...credential, bizType:'b2b'});
        console.log('B2B signin result:', data);

        if (
            data.accessToken
            // && data.refreshToken     // FIXME refreshToken이 정상적으로 내려오도록 수정한 후에...
        ) {
            // 로그인에 성공 --> local에 토큰 저장
            setBoToken({accessToken: data.accessToken, refreshToken: data.refreshToken});
            setBoUserType('b2b');

            // 사용자 정보를 읽어 반환
            //Gary:240106: set이후 바로적용 안되서 오류 발생 -> 아래라인으로 개선.  let userInfo = await axiosGet(`/vws/bo/user/info`, {username: boUserId});
            let userInfo = await axiosGet(`/vws/bo/user/info`, {username: data.username});
            console.log('After 2AI B2B signin: userInfo', userInfo);

            return userInfo && userInfo;
        }

        throw new Error("2AI B2B signin failed");
    }

    return (
        <EndorseLayout title={'채권 2AI 기업회원 로그인'}>
            {contextHolder}
            <TitleBox style={{marginTop:70}}> 이메일로 로그인 </TitleBox>

            <br/>
            <br/>
            <FlexColumnCenter>
                <Form layout="vertical" style={{width:300}} requiredMark={false} size="large"
                      onFinish={onLoginFormFinish} onFinishFailed={onLoginFormFinishFailed} autoComplete="off"
                >
                    <Form.Item name="email"
                               rules={[
                                   {
                                       required: true,
                                       message: '유효한 이메일 주소를 입력해주세요',
                                       pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                                   }
                               ]}
                    >
                        <Input ref={emailInputRef} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="이메일 주소"/>
                    </Form.Item>
                    <Form.Item name="password"
                               rules={[
                                   {
                                       required: true,
                                       message: '비밀번호를 입력해주세요'
                                   }
                               ]}
                    >
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="비밀번호"/>
                    </Form.Item>
                    <Space />
                    <Form.Item>
                        <Button htmlType="submit" style={{marginTop:20, color:'white', fontSize:14, backgroundColor:'black', width:300}} loading={loading}>로그인</Button>
                    </Form.Item>
                </Form>

                <FlexCenter style={{marginTop:10}}>
                    <GrayDivLink onClick={()=>{navigate('/endorsement/signup')}}> <b>이메일로 회원가입</b> </GrayDivLink>
                </FlexCenter>
            </FlexColumnCenter>

            <br/>
            <br/>
            <br/>




        </EndorseLayout>
    )
}

export default EndorseLogin;