import React, {useState, useEffect} from "react";
import {Button, Space} from "antd";
import {Flex, FlexCenter, FlexColumn, FlexRight, TitleBox, GrayDiv, GrayBox} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import EndorseLayout from "./EndorseLayout";


//props: type='signup', 'predata' 2가지로 구부해서 전송.
const B2bSignupWait = (props) => {


    return (
        <EndorseLayout title={'채권도시 기업회원 로그인'}>

            <TitleBox style={{marginTop: 70}}> 승인이 되고 나면 </TitleBox>
            <TitleBox style={{marginTop: 0}}> 승인결과 이메일을 보내드립니다. </TitleBox>

        </EndorseLayout>
    )
}

export default B2bSignupWait;